import styled, { css } from 'styled-components'
import {vw} from '../utilities/helpers';

export default styled.figure`
    position: absolute;
    width: ${vw(6)};
    height: ${vw(7.5)};
    background-position: center;
    background-repeat: no-repeat;

    left: ${props => props.left ? props.left : 'auto'};
    right: ${props => props.right ? props.right : 'auto'};
    top: ${props => props.top ? props.top : 'auto'};
    z-index: ${props => props.index ? props.index : '0'};

    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media (max-width: 1023px){
        ${props => props.home && css`
            width: 50%;
            top: ${vw(3.5)};
        `}
    }

    @media (max-width: 767px){
        position: static;
        width: 80%;
        margin: 0 auto;
        height: auto;
    }
`