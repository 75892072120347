import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import { vw, createOptions } from '../utilities/helpers'
import SEO from '../utilities/seo'
import Button from '../components/button'

import Block from '../components/block'
import Bit from '../components/bit'
import Glow from '../components/glow'
import Parallax from '../components/parallax'
import Hero from '../components/hero'
import Form from '../components/form'
import Footer from '../components/footer'
import Text from '../components/text'

import photo from '../assets/images/placeholders/cut-d.png'
import {countryList, districtList} from '../utilities/data'

const Wrap = styled.section`
    position: relative; 
    margin-left: ${vw(3)};
    width: ${vw(17)};
    margin-top: ${vw(3.5)};

    h1{
        padding-bottom: ${vw(1)};

        span:first-child{
            padding-bottom: ${vw(0.25)}
        }
    }

    @media (max-width: 1023px){
        width: 90%;
        margin-left: 5%;
    }

    @media (max-width: 767px){
        width: 80%;
        margin: 0 auto;

        h1 {
            padding-bottom: 48px;
        }
    }
`

export default () => {
    return (
        <>
        <main>
            <SEO title="Onboard" />
            
            <Block>
                <Glow royal top="-15%" left={vw(-4)} delay="0" />
                <Bit arrow top="25%" right="15%" />
                <Bit arrow top="45%" right="15%" />

                <Glow warm bottom="-50%" right={vw(-4)} delay="0.75" />
                <Bit ring top="55%" right="35%" />

                <Wrap>
                    <Hero right="0" top="0">
                        <img src={photo} alt="Intergenerational dialogue" />
                    </Hero>
                    <h1>
                        <Text xlarge>Registration for IGD 2019 is now closed.</Text>
                        {/* <Text small>* Field required</Text> */}
                    </h1>

                    {/* <Form>
                        <form method="post" name="attend" action="/thank-you" data-netlify-honeypot="mysterio" data-netlify="true">
                            <input type="hidden" name="mysterio" />
                            <input type="hidden" name="form-name" value="attend" />
                            
                            <section>
                                <div>
                                    <label><Text tiny>First Name*</Text></label>
                                    <input type="text" name="firstname" required />
                                </div>
                            </section>

                            <section>
                                <div>
                                    <label><Text tiny>Last Name*</Text></label>
                                    <input type="text" name="lastname" required />
                                </div>
                            </section>

                            <section>
                                <div>
                                    <label><Text tiny>Email*</Text></label>
                                    <input type="email" name="email" required />
                                </div>
                            </section>

                            <section>
                                <div>
                                    <label><Text tiny>Phone Number*</Text></label>
                                    <input type="text" name="phone number" required />
                                </div>
                            </section>
                            <section>
                                <div>
                                    <label><Text tiny>Gender*</Text></label>
                                    <select required name="gender" id="">
                                        <option>-</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </section>
                            <section>
                                <div>
                                    <label><Text tiny>Age*</Text></label>
                                    <select name="age" id="">
                                        <option>-</option>
                                        <option value="10 - 14">10 - 14 years</option>
                                        <option value="15 - 19">15 - 19</option>
                                        <option value="20 - 24">20 - 24</option>
                                        <option value="25 - 29">25 - 29</option>
                                        <option value="30 - 34">30 - 34</option>
                                        <option value="35 - 50">35 - 50</option>
                                        <option value="51+">51+</option>
                                    </select>
                                </div>
                            </section>
                            <section>
                                <div>
                                    <label><Text tiny>Country*</Text></label>
                                    <select required name="country" defaultValue="Uganda">
                                        {countryList.map(createOptions)}
                                    </select>
                                </div>
                            </section>
                            <section>
                                <div>
                                    <label><Text tiny>District</Text></label>
                                    <select name="district" defaultValue="-">
                                        {districtList.map(createOptions)}
                                    </select>
                                </div>
                            </section>
                            <section>
                                <div>
                                    <label><Text tiny>Organization/School*</Text></label>
                                    <input type="text" name="organization" required/>
                                </div>
                            </section>
                            <section>
                                <div>
                                    <label><Text tiny>Designation</Text></label>
                                    <input type="text" name="designation" />
                                </div>
                            </section>
                            <section className="full">
                                <div>
                                    <label><Text tiny>Which breakaway session would you like to attend?*</Text></label>
                                    <select name="sessions" required>
                                        <option>-</option>
                                        <option value="Let's Talk - A holistic Approach to early and unintended Pregnancy">Let's Talk - A holistic Approach to early and unintended Pregnancy</option>
                                        <option value="Lifestyle or Unmet Needs? What is the Cost of Restricted SRHR information and services for  young people">Lifestyle or Unmet Needs? What is the Cost of Restricted SRHR information and services for  young people</option>
                                        <option value="The big Question: Why should  the National School Health Policy pass? ">The big Question: Why should  the National School Health Policy pass? </option>
                                        <option value="SRHR  and Uganda’s development reality">SRHR  and Uganda’s development reality</option>
                                        <option value="IGD Powerspeak">IGD Powerspeak</option>
                                    </select>
                                </div>
                            </section>
                            <section className="full">
                                <Button label="Register" button></Button>
                            </section>

                            <section className="full gdpr">
                               <p><Text small>By clicking submit, you agree to the <Link to="/privacy-policy">Terms of Use and Privacy policy</Link> of IGD</Text></p>
                            </section>
                        </form>
                    </Form> */}
                </Wrap>
            
            </Block>
                       
            <Parallax c/>
        </main>
        <Footer/>
        </>
    )
}
