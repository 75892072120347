import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import inView from 'in-view'

import {paths} from '../utilities/helpers'

const Wrap = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -5;
    top: 0;
    left: 0;

    opacity: 0;
    transition: opacity 0.5s ease;

    svg{
        width: 100%;
        height: 100%;
        transform: scale3d(1.25,1.25,1.25)
    }

    &.is-visible{
        opacity: 0.3
    }
`

export default(props) => {
    const [visible, setVisible] = useState('')

    useEffect(() => {
        const front = document.getElementsByClassName('frontPath')
        const back = document.getElementsByClassName('backPath')
        const ele = document.getElementsByClassName('page-parallax')

        const onScroll = () => {

            if(ele[0]) {
                if (inView.is(ele[0])) {
                    setVisible('is-visible')
                }
            }
            
            const pageOffset = window.pageYOffset

            if(front[0] && back[0]){
                if (props.b || props.d || props.e) {
                    front[0].style.transform = `translate3d(${pageOffset * 0.04}px, 0, 0)`
                    back[0].style.transform = `translate3d(${pageOffset * -0.07}px, 0, 0)`
                }

                if (props.a || props.c) {
                    front[0].style.transform = `translate3d(0, ${pageOffset * -0.02}px, 0)`
                    back[0].style.transform = `translate3d(0, ${pageOffset * -0.07}px, 0)`
                }
            }
        }
        onScroll()
        document.addEventListener("scroll", onScroll)
        return () => {
            document.addEventListener("scroll", onScroll)
        }
    })


    return (
        <Wrap className={'page-parallax '+visible}>
            {props.a && 
                <svg viewBox="0 0 1320 720" preserveAspectRatio="xMidYMid slice">
                    <defs>
                        <linearGradient id="a-back" gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                            <stop offset="0" stopColor="#7D00F0" />
                            <stop offset="0.4" stopColor="#2A1695" />
                        </linearGradient>
                        <linearGradient id="a-front" gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                            <stop offset="0.2" stopColor="#7D00F0" />
                            <stop offset="1" stopColor="#2A1695" />
                        </linearGradient>
                    </defs>
                    <g transform="translate(660, -72)">
                        <path className="backPath" fill="url(#a-back)" d={paths.g} />
                    </g>
                    <g transform="translate(660, 72)">
                        <path className="frontPath" fill="url(#a-front)" d={paths.h} />
                    </g>
                </svg> 
            }

            {props.b &&
                <svg viewBox="0 0 1320 720" preserveAspectRatio="xMidYMid slice">
                    <defs>
                        <linearGradient id="b-front" gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                            <stop offset="0" stopColor="#7D00F0" />
                            <stop offset="0.8" stopColor="#2A1695" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient id="b-back" gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                            <stop offset="0" stopColor="#7D00F0" stopOpacity="0" />
                            <stop offset="0.7" stopColor="#2A1695" />
                        </linearGradient>
                    </defs>
                    <g transform="translate(10, 0)">
                        <path className="frontPath" fill="url(#b-front)" d={paths.k} />
                    </g>
                    <g transform="translate(120, 0)">
                        <path className="backPath" fill="url(#b-back)" d={paths.j} />
                    </g>
                </svg>  
            }

            {props.c && 
                <svg viewBox="0 0 1320 720" preserveAspectRatio="xMidYMid slice">
                    <defs>
                        <linearGradient id="c-front" gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                            <stop offset="0" stopColor="#7D00F0" stopOpacity="0" />
                            <stop offset="0.8" stopColor="#2A1695" />
                        </linearGradient>
                        <linearGradient id="c-back" gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                            <stop offset="0" stopColor="#7D00F0" />
                            <stop offset="0.7" stopColor="#2A1695" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                    <g transform="translate(0, 0)">
                        <path className="backPath" fill="url(#c-back)" d={paths.b} />
                    </g>

                    <g transform="translate(0, 0)">
                        <path className="frontPath" fill="url(#c-front)" d={paths.c} />
                    </g>
                </svg>
            }

            {props.d && 
                <svg viewBox="0 0 1320 720" preserveAspectRatio="xMidYMid slice">
                    <defs>
                        <linearGradient id="d-front" gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                            <stop offset="0" stopColor="#7D00F0" stopOpacity="0" />
                            <stop offset="1" stopColor="#2A1695" />
                        </linearGradient>
                        <linearGradient id="d-back" gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                            <stop offset="0" stopColor="#7D00F0" />
                            <stop offset="1" stopColor="#2A1695" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                    <g transform="translate(0, 0)">
                        <path className="backPath" fill="url(#d-back)" d={paths.e} />
                    </g>

                    <g transform="translate(200, 0)">
                        <path className="frontPath" fill="url(#d-front)" d={paths.c} />
                    </g>
                </svg>
            }

            {props.e && 
                <svg viewBox="0 0 1320 720" preserveAspectRatio="xMidYMid slice">
                    <defs>
                        <linearGradient id="e-front" gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                            <stop offset="0" stopColor="#7D00F0" />
                            <stop offset="1" stopColor="#2A1695" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient id="e-back" gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                            <stop offset="0" stopColor="#7D00F0" stopOpacity="0" />
                            <stop offset="1" stopColor="#2A1695" />
                        </linearGradient>
                    </defs>
                    <g transform="translate(720, 0)">
                        <path className="backPath" fill="url(#e-back)" d={paths.j} />
                    </g>
                    <g transform="translate(720, 0)">
                        <path className="frontPath" fill="url(#e-front)" d={paths.k} />
                    </g>
                </svg>
            }
        </Wrap>
    )
}
