import React, { useRef, useState, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { vw } from '../utilities/helpers'
import inView from 'in-view'

if (typeof window !== 'undefined') {
  inView.threshold(0.5)
}

const pulse = keyframes`
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.8;
  }
`

const Glow = styled.div`
    width: ${vw(12)};
    height: ${vw(12)};
    position: absolute;
    z-index: -10;
    
    animation: ${pulse} 5s ease infinite both;
    animation-delay: ${props => props.delay}s;
    transform: translate3d(0,0,0);
    animation-play-state: paused;
    
    ${props => props.bottom && css`
        bottom: ${props.bottom};
    `}

    ${props => props.top && css`
        top: ${props.top};
    `}

    ${props => props.left && css`
        left: ${props.left};
    `}

    ${props => props.right && css`
        right: ${props.right};
    `}

    ${props => props.warm && css`
       background-image: radial-gradient(#fbcf53 -30%,rgba(0,0,0,0) 70%);
    `}

    ${props => props.cool && css`
       background-image: radial-gradient(#00b8ff -30%,rgba(0,0,0,0) 70%);
    `}

    ${props => props.royal && css`
       background-image: radial-gradient(#745fda -30%,rgba(0,0,0,0) 70%);
    `}

    &.is-visible{
      animation-play-state: running;
    }

    @media (max-width: 767px){
      width: ${vw(30)};
      height: ${vw(30)};
    }
`

export default (props) => {
  const obj = useRef(null)
  const [visibility, setVisibility] = useState('is-visible')

  useEffect(() => {
    const onScroll = () => {
      if (!inView.is(obj.current)) {
        setVisibility('')
      } else {
        setVisibility('is-visible')
      }
    }

    onScroll()

    document.addEventListener("scroll", onScroll)

    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  })

  return (
    <Glow {...props} ref={obj} className={visibility}></Glow>
  )
}
