import styled from 'styled-components'
import {vw, colors} from '../utilities/helpers'

export default styled.div`
    width: ${vw(9)};
    margin-left: ${vw(1)};

    form {
        :after{
            content: "";
            display: table;
            clear: both;
        }

        > section{
            padding-bottom: ${vw(0.5)};
            width: 50%; 
            float: left;
            position: static;

            > div {
                padding-right: ${vw(0.5)}
            }

            &.full{
                width: 100%;
            }
        }
    }

    .gdpr{
        
        p{
            padding: ${vw(0.5)} 0;
        }

        a {
            text-decoration: underline;
        }
    }

    :after{
        content: "";
        display: table;
        clear: both;
    }

    textarea, select, input[type="text"], input[type="email"]{
        border-bottom: solid 2px ${colors.green};
        padding: ${vw(0.2)} 0 ${vw(0.2)};
        width: 100%; 
        color: #fff;
        font-family: 'Quicksand', sans-serif;
        font-weight: 700;
    }

    input[type="submit"]{
        background-color: red;
        border: none;
        color: white;
    }

    label{

        span {
            text-transform: uppercase;
            display: block;
            font-weight: 700;
            opacity: 0.65;
        }
    }

    button{
        margin-top: ${vw(0.5)};
    }

    @media (max-width: 1023px) {
        width: 65%;
        margin-left: 5%;

        textarea, select, input[type="text"], input[type="email"]{
            padding: ${vw(0.35)} 0 ${vw(0.2)};
        }

    }

    @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;

        form > section {
            float: none; 
            width: auto;
            padding-bottom: 30px;

            > div {
                padding-right: 0;
            }
        }
    }
`