import React, { useState, useEffect, useRef } from 'react'
import styled, {css} from 'styled-components'
import inView from 'in-view'

import { vw } from '../utilities/helpers'

const Block = styled.section`
    position: relative;
    min-height: ${props => props.flex === 'true' ? '0' : vw(13)}; 
    z-index: 0;
    padding-bottom: ${props => props.flex === 'true' ? vw(2) : '0'};

    opacity: 0;
    transform: translate3d(0, ${vw(3)}, 0) skewY(10deg);
    transition: transform 1s, opacity 1s;

    &.is-visible{
        transform: translate3d(0,0,0) skewY(0deg);
        opacity: 1
    }

    @media (max-width: 1023px){
        ${props => props.index && css`
            padding: ${vw(3.5)} 0;
        `}
    }

    @media (max-width: 767px){
        min-height: 0;
        padding-bottom: 96px;
        width:100vw;
    }
`

export default(props) => {
    const [visible, setVisible] = useState('')
    const obj = useRef(null)

    useEffect(() => {
        inView.threshold(0)

        const submenu = document.getElementsByClassName('submenu')
        const main = document.getElementsByClassName('page-content')
        const footer = document.getElementsByClassName('page-footer')
        const preface = document.querySelector('.content-block')
        

        const onScroll = () => {

            if (inView.is(obj.current)) {
                setVisible('is-visible')
            }

            if(submenu[0] && preface){
                main[0] && (inView.is(main[0]) && !inView.is(preface) && !inView.is(footer[0])) ? submenu[0].classList.add('is-visible') : submenu[0].classList.remove('is-visible')
            }   
        }

        onScroll()
        document.addEventListener("scroll", onScroll)

        return () => {
            document.removeEventListener("scroll", onScroll)
        }
    })

    return (
        <Block ref={obj} className={'content-block ' + visible} flex={props.flex} index={props.index}>
            {props.children}
        </Block>
    )
}